import React, { useEffect, useState } from "react";

const ALLOWED_USER_AGENT = "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36"; // Reemplaza con el User-Agent permitido

const ConsultaMultas = () => {
  const [isAllowed, setIsAllowed] = useState(false);
  const [tipo, setTipo] = useState("Licencia");
  const [identificacion, setIdentificacion] = useState("");

  // Verificar el User-Agent
  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent === ALLOWED_USER_AGENT) {
      setIsAllowed(true);
    } else {
      setIsAllowed(false);
    }
  }, []);

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isAllowed) {
      alert("Acceso denegado: User-Agent no permitido.");
      return;
    }

    // Define la URL según el tipo de identificación
    const url =
      tipo === "Licencia"
        ? "https://consultaweb.ant.gob.ec/PortalWEB/paginas/clientes/clp_grid_citaciones.jsp"
        : "https://sistematransito.ant.gob.ec/PortalWEB/paginas/clientes/clp_grid_citaciones.jsp";

    // Crea un formulario para enviar los datos
    const form = document.createElement("form");
    form.method = "POST";
    form.action = url;

    // Campo oculto para el tipo de identificación
    const tipoInput = document.createElement("input");
    tipoInput.type = "hidden";
    tipoInput.name = "ps_tipo_identificacion";
    tipoInput.value =
      tipo === "Licencia"
        ? "CED"
        : tipo === "Placa"
        ? "PLA"
        : tipo === "RUC"
        ? "RUC"
        : "PAS";
    form.appendChild(tipoInput);

    // Campo para la identificación
    const identificacionInput = document.createElement("input");
    identificacionInput.type = "hidden";
    identificacionInput.name = "ps_identificacion";
    identificacionInput.value = identificacion;
    form.appendChild(identificacionInput);

    // Agrega el formulario al DOM y envíalo
    document.body.appendChild(form);
    form.submit();
  };

  if (!isAllowed) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md text-center">
          <h2 className="text-xl font-bold text-red-600 mb-4">Acceso Denegado</h2>
          <p className="text-gray-700">
            Este aplicativo solo puede visualizarse en la aplicacion Info Transito Ecuador.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">
          Consulta de Multas
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="tipo" className="block text-gray-600 mb-1">
              Tipo de Identificación
            </label>
            <select
              id="tipo"
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
            >
              <option value="Licencia">Licencia</option>
              <option value="Placa">Placa</option>
              <option value="RUC">RUC</option>
              <option value="Pasaporte">Pasaporte</option>
            </select>
          </div>
          <div>
            <label htmlFor="identificacion" className="block text-gray-600 mb-1">
              Ingrese Identificación
            </label>
            <div className="relative">
              <input
                type="text"
                id="identificacion"
                value={identificacion}
                onChange={(e) => setIdentificacion(e.target.value)}
                placeholder="Ingrese Identificación"
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              />
              <span className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400">
                <i className="fas fa-id-card"></i>
              </span>
            </div>
          </div>
          <button
            type="submit"
            className="w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
          >
            Buscar
          </button>
        </form>
      </div>
    </div>
  );
};

export default ConsultaMultas;
